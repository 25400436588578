var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2 control-label",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" ID ")]), _c('div', {
    staticClass: "col-sm-6"
  }, [_vm._v(" " + _vm._s(_vm.user ? _vm.user.id : '') + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2 control-label",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Email ")]), _c('div', {
    staticClass: "col-sm-6"
  }, [_vm._v(" " + _vm._s(this.$route.params.id) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2 control-label",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Active ")]), _vm.user ? _c('div', {
    staticClass: "col-sm-6"
  }, [_vm.user.is_active ? _c('span', {
    staticClass: "badge badge-pill badge-success"
  }, [_vm._v(" Active ")]) : _c('span', {
    staticClass: "badge badge-pill badge-success"
  }, [_vm._v(" In Active ")])]) : _vm._e()]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2 control-label",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Last Login ")]), _vm.user ? _c('div', {
    staticClass: "col-sm-6"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.user.last_login)) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "centering form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2 control-label",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Banned ")]), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userBannedDetailUserId.is_active,
      expression: "userBannedDetailUserId.is_active"
    }],
    class: {
      isBannedToggle: _vm.userBannedDetailUserId.is_active
    },
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.userBannedDetailUserId.is_active) ? _vm._i(_vm.userBannedDetailUserId.is_active, null) > -1 : _vm.userBannedDetailUserId.is_active
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.userBannedDetailUserId.is_active,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.userBannedDetailUserId, "is_active", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.userBannedDetailUserId, "is_active", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.userBannedDetailUserId, "is_active", $$c);
        }
      }, function ($event) {
        return _vm.actionBoolean(_vm.userBannedDetailUserId.is_active);
      }]
    }
  }), _c('span', {
    staticClass: "slider"
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }