<template>
  <b-col lg="12">
    <div class="form-group row">
      <label for="" class="col-sm-2 control-label"> ID </label>
      <div class="col-sm-6">
        {{ user ? user.id :'' }}
      </div>
    </div>
    <div class="form-group row">
      <label for="" class="col-sm-2 control-label"> Email </label>
      <div class="col-sm-6">
        {{ this.$route.params.id }}
      </div>
    </div>
    <div class="form-group row">
      <label for="" class="col-sm-2 control-label">  Active </label>
      <div class="col-sm-6" v-if="user">
        <span v-if ="user.is_active" class="badge badge-pill badge-success">
          Active
        </span>
        
        <span v-else class="badge badge-pill badge-success">
          In Active
        </span>
      </div>
    </div>
    <div class="form-group row">
      <label for="" class="col-sm-2 control-label"> Last Login </label>
      <div class="col-sm-6" v-if="user">
        {{ formatDate(user.last_login) }}
      </div>
    </div>
      <div class="centering form-group row">
        <label for="" class="col-sm-2 control-label"> Banned </label>
        <label class="switch">
          <input  type="checkbox"  v-model="userBannedDetailUserId.is_active" :class="{ isBannedToggle: userBannedDetailUserId.is_active }"
            @change="actionBoolean(userBannedDetailUserId.is_active)">
          <span class="slider"></span>
        </label>
      </div>
    
  </b-col>
</template>

<script>
import moment from "moment";
import { mapActions, mapMutations } from "vuex";
import API from "../../plugins/http"

export default {
  name: "Profile",
  data() {
    return {
      isBanned: null,
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    userBannedDetailUserId: {
    type: Object, // Change made here from Array to Object
    default: () => ({}), // Default also changed to return an empty object
    deep: true
  },
  },
  mounted() {
   this.fetchBannedData();
  },
  methods: {
    ...mapActions("users", ["fetchBanned"]),
    ...mapMutations("users", ["setItems", "setRows"]),
    async fetchBannedData() {
      try {
        let data = {
          email: this.$route.params.id,
        };
        await this.fetchBanned(data);   // console.log('is band', this.isBanned)
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    actionBoolean(param) { //const endpoint = param.is_active ? this.Banned : this.Unbanned
      this.actionConfirm(param);
    },
     actionConfirm(banned_unbanned) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: `${banned_unbanned === true ?  "You want be able to banned" : 'You want be able to unbanned'}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: `Yes,  it!`,
        })
        .then((result) => {
          if (result.isConfirmed) {
           
          let payload = {
              user_id: this.user.id,
              is_banned: this.userBannedDetailUserId.is_active
            };
           

            API.post(`/user-banned/banned-unbanned/`, payload)
              .then(() => {
                this.$swal({
                  toast: "true",
                  position: "top-end",
                  icon: "success",
                  title: `${banned_unbanned === true ?  "banned" : 'Unbanned'} Success `,
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                })
                this.fetchBannedData()
              }).catch((error) => {
                let response = error.response
                if (response.status == 403) {
                  this.$swal({
                    toast: "true",
                    position: "top-end",
                    icon: "error",
                    title:`You Do not have access to  Users`,
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  })
                } else {
                  this.$swal({
                    toast: "true",
                    position: "top-end",
                    icon: "error",
                    title: `Failed  Pelase Contact Admin`,
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  })
                }
              })
          } else {
          this.userBannedDetailUserId.is_active = !this.userBannedDetailUserId.is_active;
          }
        });
    },
  },
}
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #dedede;
  border-radius: 40px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 32px;
  background: #fff;
  border-radius: 50%;
  left: 0px;
  bottom: -1px;
  transition: 0.4s;
  border: 1px solid #ccc
}

input[type="checkbox"].isBannedToggle:checked+.slider {
  background: #0060AF !important;
}

input[type="checkbox"].isBannedToggle:checked+.slider:before {
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  transform: translateX(30px);
}
</style>
